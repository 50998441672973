<template>
  <div class="eidtExamPlaceBox">
    <!-- 按钮区域 -->
    <div class="btnBox">
      <el-button
        v-throttle
        @click="
          () => {
            this.$router.back();
          }
        "
        >返回</el-button
      >
      <el-button v-throttle type="primary" @click="handleAddExamGroupBtn"
        >添加分组</el-button
      >
    </div>

    <!-- 表格区域 -->
    <el-table
      border
      class="groupInfoTable"
      :data="groupInfoData"
      :span-method="arraySpanMethod"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 20px"
      :header-cell-style="{
        'text-align': 'center',
        backgroundColor: '#dfe6ec',
      }"
      :cell-style="{
        'text-align': 'left',
        backgroundColor: 'rgb(249,249,249)',
      }"
      @row-click.capture="handleRowClick"
      :expand-row-keys="expandRowKeys"
      :row-key="tableRowKey"
    >
      <el-table-column type="expand">
        <template slot-scope="scoped">
          <el-table
            :show-header="false"
            :data="examPlaceGroupData[scoped.row.groupCode]"
            style="width: 100%"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column width="48">
              <template slot-scope="scoped">
                <span>{{ scoped.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="考试名称"
              center
            ></el-table-column>
            <el-table-column
              prop="code"
              label="考试代码"
              center
            ></el-table-column>
            <el-table-column prop="subjectType" label="科目" center width="150">
              <template slot-scope="scoped">
                <span v-if="scoped.row.subjectType === 1">物理</span>
                <span v-else-if="scoped.row.subjectType === 2">生物</span>
                <span v-else-if="scoped.row.subjectType === 3">化学</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="seatCount"
              label="座位数量"
              center
              width="150"
            ></el-table-column>
            <el-table-column
              prop="mainMeiaUrl"
              label="媒体流"
              center
            ></el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              center
            ></el-table-column>
            <el-table-column label="操作" center>
              <template slot-scope="scoped1">
                <span
                  v-if="scoped.row.isEditExamGroup"
                  style="
                    cursor: pointer;
                    color: rgb(58, 168, 255);
                    font-size: 16px;
                  "
                  @click="handleEditExamPlaceBtn(scoped1.row)"
                  >编辑</span
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="考试名称" prop="groupName">
        <template slot-scope="scoped">
          <el-form
            :ref="'examPlaceInfoForm' + scoped.row.groupCode"
            :rules="rules"
            class="testtttttttttttt"
            :model="scoped.row"
            style="display: flex; height: 35px; line-height: 35px"
          >
            <div style="font-size: 16px; margin-right: 5px">
              <span
                v-if="!scoped.row.isEditExamGroup"
                style="height: 35px; line-height: 35px"
                >{{ scoped.row.groupName }}</span
              >
              <el-form-item v-else prop="groupName">
                <el-input
                  maxlength="20"
                  v-model.trim="scoped.row.groupName"
                  style="width: auto; margin-right: 10px"
                  required
                  size="mini"
                />
              </el-form-item>
            </div>

            <span style="color: rgb(170, 170, 170)"
              >({{ scoped.row.groupCode }})</span
            >
            <div
              v-if="!scoped.row.isEditExamGroup"
              style="
                margin-left: 10px;
                background-color: rgb(129, 211, 248);
                width: 76px;
                padding: 0 10px;
                border-radius: 5px;
                color: #fff;
              "
            >
              {{ scoped.row.groupType | groupTypeFilters }}
            </div>
            <el-form-item v-else>
              <el-select
                v-model="scoped.row.groupType"
                size="mini"
                style="width: 100px; margin-left: 10px"
              >
                <el-option label="顺序实验室" :value="1"></el-option>
                <el-option label="叠加实验室" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="考试代码" center>
        <template slot-scope="scoped">
          <div style="text-align: center; font-size: 16px">
            <span
              style="
                padding-right: 10px;
                border-right: 1px solid #000;
                margin-right: 10px;
                color: rgb(217, 0, 27);
                cursor: pointer;
              "
              @click="handleDelExamGroupBtn(scoped.row)"
              >{{
                scoped.row.isAddExamGroup || scoped.row.isEditExamGroup
                  ? "取消"
                  : "删除"
              }}</span
            >
            <span
              style="cursor: pointer; color: rgb(58, 168, 255)"
              @click="handleEditOrSaveExamGroupBtn(scoped.row)"
              >{{ scoped.row.isEditExamGroup ? "保存" : "编辑" }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="科目" center width="150"></el-table-column>
      <el-table-column label="座位数量" center width="150"></el-table-column>
      <el-table-column label="媒体流" center></el-table-column>
      <el-table-column label="备注" center></el-table-column>
      <el-table-column label="操作" center> </el-table-column>
    </el-table>

    <!-- 删除弹框区域 -->
    <el-dialog
      :visible.sync="isDelDialogVisible"
      width="450px"
      top="10%"
      :before-close="
        () => {
          isDelDialogVisible = false;
        }
      "
    >
      <div
        style="
          position: absolute;
          left: 25px;
          top: 25px;
          height: 30px;
          line-height: 30px;
          display: flex;
        "
      >
        <img
          style="object-fit: cover; width: 30px"
          src="./img/waringPoint.png"
        />
        <div style="font-size: 16px; margin-left: 5px">是否确认删除分组？</div>
      </div>
      <div
        style="
          margin-top: 25px;
          margin-left: 25px;
          font-size: 16px;
          width: 350px;
        "
      >
        删除分组后，分组内的实验室信息也会同步删除，请谨慎操作！
      </div>
      <div style="text-align: center; margin-top: 50px">
        <el-button
          style="width: 80px; margin-right: 15px"
          v-throttle
          @click="
            () => {
              isDelDialogVisible = false;
            }
          "
          >取消</el-button
        >
        <el-button
          style="width: 80px"
          type="primary"
          @click="confirmDelGroupBrn"
          v-throttle
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑实验室区域 -->
    <el-dialog
      :visible.sync="isEditExamPlaceDialogVisible"
      :before-close="
        () => {
          isEditExamPlaceDialogVisible = false;
        }
      "
      width="1600px"
    >
      <!-- 头部标题 -->
      <div class="addExamPlaceTitle">编辑实验室</div>
      <!-- 中部表格 -->
      <div class="editExamPlaceForm">
        <div class="leftFormContainer">
          <div class="leftFormTitle">实验室信息</div>
          <el-form
            :model="examPlaceRoomForm"
            :rules="rules"
            ref="examPlaceRoomForm"
            label-width="120px"
            class="demo-ruleForm"
          >
            <div class="addAll">
              <el-form-item class="formItem" label="实验室名称" prop="roomName">
                <el-input
                  v-model.trim="examPlaceRoomForm.roomName"
                  placeholder="请输入"
                  maxlength="12"
                  onkeyup="this.value=this.value.replace(/\s/g,'')"
                ></el-input>
              </el-form-item>

              <el-form-item class="formItem" label="实验室代码" prop="roomId">
                <el-input
                  v-model="examPlaceRoomForm.roomId"
                  placeholder="请输入"
                  maxlength="16"
                ></el-input>
              </el-form-item>
              <el-form-item class="formItem" label="实验室科目">
                <el-select
                  v-model="value"
                  placeholder="请选择科目"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="formItem" label="座位数量">
                <el-input
                  v-model="examPlaceRoomForm.seatTotal"
                  placeholder
                  oninput="value=value.replace(/[^0-9]/g,'');if(value>81)value=81;"
                  type="number"
                  @blur="reviseSeatNum"
                ></el-input>
              </el-form-item>
              <el-form-item class="formItem examPlaceGroup" label="实验室分组">
                <el-select v-model="groupValue" style="width: 100%" disabled>
                </el-select>
              </el-form-item>
              <el-form-item class="formItem" label="备用机">
                <el-input
                  v-model.number="examPlaceRoomForm.spareMechine"
                  placeholder="请输入"
                  maxlength="1"
                ></el-input>
              </el-form-item>
              <el-form-item class="formItem" label="媒体流">
                <el-input
                  v-model="examPlaceRoomForm.media"
                  placeholder="请输入"
                  maxlength="225"
                ></el-input>
              </el-form-item>
              <el-form-item class="formItem" label="备注">
                <el-input
                  v-model="examPlaceRoomForm.remarks"
                  placeholder="请输入"
                  maxlength="225"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="rightFormContainer">
          <!-- 第一行 -->
          <div class="rightFormTitle">
            <div style="font-size: 20px">座位布局</div>
            <div>
              <span>行：</span>
              <el-select
                style="width: 60px; margin-right: 10px"
                placeholder="请选择"
                size="medium"
                @change="changeRowCol"
                v-model="setSeatForm.setSeatRow"
              >
                <el-option
                  v-for="v in 9"
                  :key="v"
                  :label="v"
                  :value="v"
                ></el-option>
              </el-select>
              <span>列：</span>
              <el-select
                style="width: 60px"
                placeholder="请选择"
                size="medium"
                @change="changeRowCol"
                v-model="setSeatForm.setSeatCol"
              >
                <el-option
                  v-for="v in 9"
                  :key="v"
                  :label="v"
                  :value="v"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- 第二行 -->
          <div style="text-align: center; margin-top: 20px">
            <span style="font-size: 16px; color: #303133">快速布局</span>
            <span
              :class="['box', isTransverse ? 'box-select' : '']"
              @click="transverse"
              >横排顺序</span
            >
            <span
              :class="['box', isVertical ? 'box-select' : '']"
              @click="vertical"
              >竖排顺序</span
            >
            <span
              :class="['box', istransverseSnake ? 'box-select' : '']"
              @click="transverseSnake"
              >横排蛇形</span
            >
            <span
              :class="['box', isverticalSnake ? 'box-select' : '']"
              @click="verticalSnake"
              >竖排蛇形</span
            >
          </div>
          <!-- 第三行 -->
          <div
            class="box"
            style="
              opacity: 0.8;
              background-color: #09c0c8;
              margin-left: 450px;
              margin-top: 30px;
            "
          >
            <img src="@/assets/tablePic.png" alt />
            <span style="color: #ffffff">讲台</span>
          </div>
          <!-- 第四行 -->
          <div style="text-align: center">
            <div
              style="margin-top: 15px"
              v-for="(k, j) in setSeatForm.setSeatRow"
              :key="j"
            >
              <div
                style="
                  margin-left: 5px;
                  margin-right: 50px;
                  display: inline-block;
                  position: relative;
                "
                v-for="(v, i) in setSeatForm.setSeatCol"
                :key="i"
              >
                <div class="seatNumber">
                  {{ seatNumberArr[i + j * setSeatForm.setSeatCol] }}
                </div>
                <el-input
                  :class="
                    inputBorder.indexOf(i + setSeatForm.setSeatCol * j + 1) !=
                    -1
                      ? 'borderRed'
                      : ''
                  "
                  :disabled="isDisabled[i + setSeatForm.setSeatCol * j]"
                  v-model.trim="seatInput[i + j * setSeatForm.setSeatCol]"
                  onkeyup="this.value=this.value.replace(/[^a-zA-Z0-9]/g, '')"
                  type="text"
                  maxlength="3"
                  style="width: 60px"
                  @blur="checkSeat"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 尾部按钮 -->
      <div style="text-align: center">
        <el-button
          v-throttle
          style="width: 100px; margin-right: 20px"
          @click="handleCancelExamPlaceRoom"
          >取消</el-button
        >
        <el-button
          style="width: 100px"
          type="primary"
          @click="handleSaveExamPlaceRoom"
          v-throttle
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getExamRoom,
  addPlaceRoom,
  editExamRoom,
  editExamRoomGroup,
  getExamRoomGroupInfo,
  delPlaceRoom,
} from "@/api/mechanism/examArea.js";
export default {
  name: "editExamPlaceManage",
  data() {
    const testGroupNameIndex = 1;
    const checkRoomId = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z]/g;
      if (reg.test(this.examPlaceRoomForm.roomId)) {
        callback(new Error("实验室代码只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      // 实验室分组信息
      groupInfoData: [
        // {
        //   groupName: "实验室分组" + testGroupNameIndex,
        //   groupCode: "rocpow11",
        //   groupType: 1,
        //   isEditExamGroup: false, // 是否正在编辑实验室分组
        //   isAddExamGroup: false, // 是否正在新增实验室分组
        // },
      ],
      tempGroupInfoData: "", // 暂存分组信息
      tempDelRowIndex: "", // 暂存要删除的分组下标信息
      testGroupNameIndex,

      //添加或编辑实验室-Room窗口
      examPlaceRoomForm: {
        roomName: "",
        roomId: "",
        subjectType: "",
        seatTotal: 24,
        examPlaceGroup: [],
        spareMechine: "",
        media: "",
        remarks: "",
        sysRoomId: "",
        sysPlaceID: "",
      },
      //编辑页内区域下拉框
      options: [
        {
          value: 1,
          label: "物理",
        },
        {
          value: 2,
          label: "生物",
        },
        {
          value: 3,
          label: "化学",
        },
      ],
      value: 1,
      groupValue: "", // 分组选择框
      // 实验室信息数组用于表格渲染
      examPlaceGroupData: {},
      examPlaceId: "",

      isDelDialogVisible: false, // 删除弹框是否可见
      isEditExamPlaceDialogVisible: false, // 编辑实验室弹框是否可见

      //控制添加实验室或编辑实验室 (true是添加实验室 false是编辑实验室)
      editOrAdd: true,
      rules: {
        groupName: [
          {
            required: true,
            message: "请输入实验室分组名称",
            trigger: "blur",
          },
        ],
        roomName: [
          { required: true, message: "请输入实验室名称", trigger: "blur" },
        ],
        roomId: [
          { required: true, message: "请输入实验室代码", trigger: "blur" },
          { validator: checkRoomId, trigger: "blur" },
        ],
        areaName: [
          { required: true, message: "请输入考点名称", trigger: "blur" },
        ],
      },
      //实验室座位布局
      setSeatForm: {
        setSeatRow: 6,
        setSeatCol: 4,
      },
      //是否是横向布局
      isTransverse: false,
      isVertical: false,
      istransverseSnake: false,
      isverticalSnake: false,
      inputBorder: [], // 用于存储多余的座位号
      isDisabled: [],
      seatInput: [],
      errorList: new Set(),
      //请求参数 座位布局二维数组
      seatLayout: [],
      // 座位序号数组
      seatNumberArr: [],
      // 备用机序号数组
      spareMechineNumberArr: [],
      spareMechineInput: [],
      spareMechineShow: [],
      spareMechineInputBorder: [],
      spareMechineErrorList: new Set(),
      expandRowKeys: [],
      tableRowKey: "groupCode",
    };
  },
  computed: {
    // 备用机的布局
    spareMechineForm() {
      const spareMechineRow = Math.ceil(
        this.examPlaceRoomForm.spareMechine / this.setSeatForm.setSeatCol
      );
      const spareMeChineCol = Math.min(
        this.examPlaceRoomForm.spareMechine,
        this.setSeatForm.setSeatCol
      );
      return {
        spareMechineRow,
        spareMeChineCol,
      };
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    // 获取数据
    fetchData() {
      let examPlaceInfo = JSON.parse(this.$route.query.examPlaceInfo);
      this.examPlaceId = examPlaceInfo.sysOrgExamPlaceId;
      this.examPlaceGroupData = {};
      getExamRoomGroupInfo(this.examPlaceId).then((res) => {
        const { data, msg } = res;
        if (res.success) {
          // 给实验室分组数组填充数据
          this.groupInfoData = data.map((item) => {
            this.$set(this.examPlaceGroupData, item.groupCode, []);
            return {
              groupName: item.groupName,
              groupCode: item.groupCode,
              groupType: item.groupType,
              isEditExamGroup: false, // 是否正在编辑实验室分组
              isAddExamGroup: false, // 是否正在新增实验室分组
            };
          });
          this.groupInfoData = this.groupInfoData.filter(
            (item) => item.groupName !== "default"
          );
          // 给实验室信息填充数据
          data.forEach((item) => {
            this.examPlaceGroupData[item.groupCode] = item.roomDTOList;
          });
          // 将所有行展开
          this.groupInfoData.forEach((item) => {
            if (this.expandRowKeys.indexOf(item.groupCode) === -1) {
              this.expandRowKeys.push(item.groupCode);
            }
          });
          this.$forceUpdate();
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 添加分组按钮
    handleAddExamGroupBtn() {
      if (this.groupInfoData.length === 3) {
        this.$message.error("分组数已满");
        return;
      }
      let code = Math.random().toString(16).substring(2, 10);
      this.testGroupNameIndex = this.groupInfoData.length + 1;
      this.groupInfoData.forEach((item) => {
        if (item.groupName === `实验室分组${this.testGroupNameIndex}`) {
          this.testGroupNameIndex++;
        }
      });
      this.groupInfoData.push({
        groupName: "实验室分组" + this.testGroupNameIndex,
        groupCode: code,
        groupType: 1,
        isEditExamGroup: true, // 正在编辑实验室分组
        isAddExamGroup: true, // 正在新增实验室分组
      });
      // 给实验室数据添加 未编辑 的实验室
      let name = "实验室分组" + this.testGroupNameIndex;
      let initSeatArrange = [];
      for (let i = 1; i <= 6; i++) {
        let arr = [];
        for (let j = 1; j <= 4; j++) {
          arr.push(j + (i - 1) * 4);
        }
        initSeatArrange.push(arr);
      }
      this.$set(this.examPlaceGroupData, code, []);
      for (let i = 1; i < 4; i++) {
        this.examPlaceGroupData[code].push({
          name: "<未编辑>",
          code: "-",
          subjectType: i,
          seatCount: 24,
          examPlaceGroup: "",
          spareMechine: "",
          mainMediaUrl: "",
          remark: "",
          sysOrgExamPlaceRoomId: "",
          sysOrgExamPlaceld: "",
          groupCode: code,
          groupName: name,
          groupType: 1,
          sysOrgExamPlaceName: "",
          assistMediaUrl: "",
          address: "",
          nameshort: "",
          seatArrange: initSeatArrange,
          seatCodeArrange: initSeatArrange,
        });
      }
      this.testGroupNameIndex++;
      // 展开该行
      this.expandRowKeys.push(code);
    },
    // 编辑或保存实验室分组按钮
    handleEditOrSaveExamGroupBtn(row) {
      this.expandCurRow(row.groupCode);
      if (row.isEditExamGroup) {
        // 这里进行保存操作
        if (row.isAddExamGroup && this.isAllExamPlaceNotEdit(row.groupCode)) {
          this.$message.error("保存失败,未进行实验室编辑！");
          return;
        }
        // 这里判断是否重名
        for (let i = 0; i < this.groupInfoData.length; i++) {
          if (
            row.groupName === this.groupInfoData[i].groupName &&
            row.groupCode !== this.groupInfoData[i].groupCode
          ) {
            this.$message.error("分组名称重复，请重新填写!");
            return;
          }
        }
        // 表单验证
        this.$refs["examPlaceInfoForm" + row.groupCode].validate((value) => {
          if (value) {
            // 这里真正进行编辑操作
            const examGroupForm = {
              groupCode: row.groupCode,
              groupName: row.groupName,
              groupType: row.groupType,
              originalGroupCode: row.groupCode,
            };
            editExamRoomGroup(examGroupForm).then((res) => {
              const { code, msg, data } = res;
              if (res.success) {
                this.$message.success("实验室分组修改成功");
                this.removeUneditExamPlaceRoom(row.groupCode);
                row.isEditExamGroup = !row.isEditExamGroup;
              } else {
                this.$message.error(msg);
                return;
              }
            });
          } else {
            this.$message.error("分组名称不能为空");
            return;
          }
        });
      } else {
        // 这里进行编辑操作
        this.tempGroupInfoData = {};
        this.tempGroupInfoData = {
          groupName: row.groupName,
          groupCode: row.groupCode,
          groupType: row.groupType,
          isEditExamGroup: false, // 是否正在编辑实验室分组
          isAddExamGroup: false, // 是否正在新增实验室分组
        };
        this.addUneditExamPlaceRoom(row);
        row.isEditExamGroup = !row.isEditExamGroup;
      }
    },
    // 取消或删除实验室分组按钮
    handleDelExamGroupBtn(row) {
      this.expandCurRow(row.groupCode);
      if (!row.isAddExamGroup && !row.isEditExamGroup) {
        this.tempDelRowIndex = this.groupInfoData.indexOf(row);
        this.isDelDialogVisible = true;
      } else if (!row.isAddExamGroup && row.isEditExamGroup) {
        // 取消编辑
        row.groupName = this.tempGroupInfoData.groupName;
        row.groupCode = this.tempGroupInfoData.groupCode;
        row.groupType = this.tempGroupInfoData.groupType;
        row.isEditExamGroup = false;
        row.isAddExamGroup = false;
        this.removeUneditExamPlaceRoom(row.groupCode);
      } else {
        const index = this.groupInfoData.indexOf(row);
        this.groupInfoData.splice(index, 1);
      }
    },
    // 确认删除按钮
    confirmDelGroupBrn() {
      if (this.groupInfoData.length === 1) {
        this.$message.error("分组数量不能小于1");
        this.isDelDialogVisible = false;
        return;
      }
      // 这里需要调用接口，删除实验室分组下的全部实验室，再删除该实验室分组；
      let preDelGroup = this.groupInfoData[this.tempDelRowIndex];
      let preDelExamRoomArr = this.examPlaceGroupData[preDelGroup.groupCode];
      let delRoomIdList = [];
      preDelExamRoomArr.forEach((item) => {
        if (item.name !== "<未编辑>") {
          delRoomIdList.push(item.sysOrgExamPlaceRoomId);
        }
      });
      delPlaceRoom(delRoomIdList).then((res) => {
        let { success, data, msg } = res;
        if (success) {
          this.$message.success("删除实验室成功");
          this.groupInfoData.splice(this.tempDelRowIndex, 1);
          this.isDelDialogVisible = false;
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 编辑实验室信息按钮
    handleEditExamPlaceBtn(row) {
      this.value = row.subjectType;
      this.spareMechineErrorList = new Set();
      this.groupValue = row.groupName || this.groupInfoData[0].groupName;
      this.setSeatForm.setSeatRow = row.seatArrange.length;
      this.setSeatForm.setSeatCol = Math.max(
        row.seatArrange[0].length,
        row.seatArrange[row.seatArrange.length - 1].length
      );
      // 回填数据
      this.examPlaceRoomForm = {
        roomName: row.name,
        roomId: row.code,
        subjectType: row.subjectType,
        seatTotal: row.seatCount,
        examPlaceGroup: [],
        spareMechine: "",
        media: row.mainMediaUrl,
        remarks: row.remark,
        sysRoomId: row.sysOrgExamPlaceRoomId,
        sysPlaceID: row.sysOrgExamPlaceId,
        groupCode: row.groupCode,
        groupName: row.groupName,
        groupType: row.groupType,
      };
      this.$nextTick(() => {
        this.seatInput = this.add2DArrSpace(row.seatCodeArrange).flat();
      });
      this.handleEditExamPlaceSeatLayoutStatus(row.seatArrange);
      this.isEditExamPlaceDialogVisible = true;

      // 如果 row.name 是 ‘<未编辑>’，说明是新增实验室的编辑
      if (row.name === "<未编辑>") {
        this.editOrAdd = true;
        this.examPlaceRoomForm.roomName = "";
        this.examPlaceRoomForm.roomId = "";
      } else {
        this.editOrAdd = false;
      }
    },
    // 该方法用于判断是否实验室都未编辑就进行保存操作, true表示都没有编辑
    isAllExamPlaceNotEdit(groupCode) {
      let result = true;
      let examPlaceArr = this.examPlaceGroupData[groupCode];
      examPlaceArr.forEach((item) => {
        if (item.name != "<未编辑>") {
          // 说明有一个编辑了
          result = false;
          return;
        }
      });
      return result;
    },
    // 合并表格行
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex !== 0) {
        return [1, 6];
      }
    },
    // 调用该方法暂存数组 seatLayout
    copySeatLayoutData() {
      //处理暂存数组seatLayout，用999作为占位符 不允许存在空值和null
      for (let i = 0; i < this.seatLayout.length; i++) {
        if (this.seatLayout.length < this.setSeatForm.setSeatCol) {
          let completedNum =
            this.setSeatForm.setSeatCol - this.seatLayout[i].length;
          for (let j = 0; j < completedNum; j++) {
            this.seatLayout[i].push("");
          }
        }
      }
      for (let i = 0; i < this.seatLayout.length; i++) {
        for (let j = 0; j < this.seatLayout[i].length; j++) {
          if (this.seatLayout[i][j] === null || this.seatLayout[i][j] === "") {
            this.seatLayout[i][j] = "";
          }
        }
      }
    },
    // 为座位数组添加数据
    pushSeatData() {
      this.seatInput = [];
      this.isDisabled = [];
      this.seatNumberArr = [];
      for (let i = 1; i < this.setSeatForm.setSeatRow + 1; i++) {
        for (let j = 0; j < this.setSeatForm.setSeatCol; j++) {
          this.seatInput.push(i + j * this.setSeatForm.setSeatRow);
          this.seatNumberArr.push(i + j * this.setSeatForm.setSeatRow);
        }
      }
      for (let i = 0; i < 81; i++) {
        this.isDisabled.push(false);
      }
    },
    // 设置座位布局的行和列
    changeRowCol() {
      //初始化重复边框提示
      this.inputBorder = [];
      this.pushSeatData();
      this.reviseSeatNum();
    },
    // 横排顺序
    transverse() {
      this.isTransverse = true;
      this.isVertical = false;
      this.istransverseSnake = false;
      this.isverticalSnake = false;
      this.reviseSeatNum1();
    },
    // 竖排顺序
    vertical() {
      this.isVertical = true;
      this.isTransverse = false;
      this.istransverseSnake = false;
      this.isverticalSnake = false;
      this.reviseSeatNum();
    },
    // 横排蛇形
    transverseSnake() {
      this.istransverseSnake = true;
      this.isVertical = false;
      this.isTransverse = false;
      this.isverticalSnake = false;
      this.reviseSeatNum2();
    },
    // 竖排蛇形
    verticalSnake() {
      this.isverticalSnake = true;
      this.isVertical = false;
      this.isTransverse = false;
      this.istransverseSnake = false;
      this.reviseSeatNum3();
    },
    // 座位号校验
    checkSeat() {
      //截取 处理为二维数组
      let preList = this.seatInput.slice(
        0,
        this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol
      );
      let avaList = this.isDisabled.slice(
        0,
        this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol
      );
      let index = 0;
      let vndex = 0;
      let newArray = [];
      let avaArray = [];
      this.inputBorder = [];
      //定义一个报错数组
      this.errorList = new Set();
      while (index < preList.length) {
        newArray.push(
          preList.slice(index, (index += this.setSeatForm.setSeatCol))
        );
      }
      while (vndex < avaList.length) {
        avaArray.push(
          avaList.slice(vndex, (vndex += this.setSeatForm.setSeatCol))
        );
      }

      //匹配座位范围 ：1-座位数量：这里判断填写数字是否大于座位号，现不做判断
      // for (let i = 0; i < this.seatInput.length; i++) {
      //   if (
      //     this.seatInput[i] > this.examPlaceRoomForm.seatTotal ||
      //     this.seatInput[i] === 0
      //   ) {
      //     this.inputBorder.push(i + 1);
      //     this.errorList.add(1);
      //   }
      // }
      //匹配是否有相同的座位号
      function flatten(data) {
        return data.flat(Infinity);
      }
      let totalSeat = this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol;
      // 添加实验室时
      let seatDiff = totalSeat - this.examPlaceRoomForm.seatTotal;
      if (this.istransverseSnake || this.isTransverse) {
        seatDiff < this.setSeatForm.setSeatCol ? "" : this.errorList.add(3);
      } else if (this.isVertical || this.isverticalSnake) {
        seatDiff < this.setSeatForm.setSeatRow ? "" : this.errorList.add(3);
      } else {
        seatDiff < this.setSeatForm.setSeatCol ? "" : this.errorList.add(3);
      }
      var indexList = [];
      var flatList = flatten(newArray);
      //自定义的过滤数组中的空值
      flatList = this.trimSpace(flatList);
      var allIndex = [];
      for (var i = 0; i < flatList.length; i++) {
        if (flatList[i] !== "" && !isNaN(+flatList[i])) {
          flatList[i] = +flatList[i];
        }
        if (
          indexList.indexOf(flatList[i]) == -1 &&
          flatList.indexOf(flatList[i]) !== flatList.lastIndexOf(flatList[i])
        ) {
          indexList.push(flatList[i]);
        }
      }
      for (i = 0; i < this.seatInput.length; i++) {
        if (this.seatInput[i] === "" && this.seatNumberArr[i] !== "") {
          this.seatInput[i] = this.seatNumberArr[i];
        }
        if (this.seatInput[i] !== "" && !isNaN(+this.seatInput[i])) {
          this.seatInput[i] = +this.seatInput[i];
        }
        if (indexList.indexOf(this.seatInput[i]) != -1) {
          allIndex.push(i + 1);
          this.errorList.add(2);
        }
      }
      this.inputBorder.push(...allIndex);
      return newArray;
    },
    //保存座位布局
    preSeat() {
      let newArray = this.checkSeat();

      //如果无重复则保存公共变量作为请求参数
      if (this.inputBorder == "") {
        //判断一下是否有整行整列为空
        if (this.errorList.has(3)) {
          this.$message.error("座位不能整行或整列为空");
          return false;
        }
        //将处理好的数组赋值给暂存数组 等待发起请求
        this.seatLayout = newArray;
        //状态判断是否点击保存 还是通过其他方式关闭dialog
        this.preState = true;
        this.dialogEditSeat = false;
      } else {
        if (this.errorList.has(1)) {
          this.$message.error("座位号只能是1-座位数量范围内的数字");
          return false;
        } else if (this.errorList.has(2)) {
          this.$message.error("座位号不能重复");
          return false;
        }
      }
      // 一切正常返回 true
      return true;
    },
    //自定义过滤""的函数
    trimSpace(array) {
      for (var i = 0; i < array.length; i++) {
        //这里为过滤的值
        if (array[i] === "") {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    },
    // 竖向布局-修改座位数量(输入框)
    reviseSeatNum() {
      if (this.isTransverse === true) {
        this.reviseSeatNum1();
      } else if (this.istransverseSnake === true) {
        this.reviseSeatNum2();
      } else if (this.isverticalSnake === true) {
        this.reviseSeatNum3();
      } else {
        // 保留之前的座位号数组
        let tempSeatNumberArr = this.seatNumberArr;
        let tempSeatInputArr = this.seatInput;
        this.isVertical = true;
        //初始化重复边框提示
        this.inputBorder = [];
        //初始化
        this.isDisabled = [];
        for (let i = 0; i < 81; i++) {
          this.isDisabled.push(false);
        }
        this.seatInput = [];
        this.seatNumberArr = [];
        for (let i = 1; i < this.setSeatForm.setSeatRow + 1; i++) {
          for (let j = 0; j < this.setSeatForm.setSeatCol; j++) {
            // this.seatInput.push(i + j * this.setSeatForm.setSeatRow);
            this.seatNumberArr.push(i + j * this.setSeatForm.setSeatRow);
          }
        }
        var j = 0;
        var z = 1;
        for (
          let t = this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol;
          j <
          this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol -
            Number(this.examPlaceRoomForm.seatTotal);
          t -= this.setSeatForm.setSeatCol
        ) {
          if (t > 0) {
            this.isDisabled[t - 1] = true;
            // this.seatInput[t - 1] = "";
            this.seatNumberArr[t - 1] = "";
            j++;
          } else {
            t =
              this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol -
              z +
              this.setSeatForm.setSeatCol;
            z++;
          }
        }
        for (
          let i = 0;
          i < this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol;
          i++
        ) {
          if (this.seatNumberArr[i] === "") {
            this.seatInput[i] = "";
          } else {
            this.seatInput[i] =
              tempSeatInputArr[
                tempSeatNumberArr.indexOf(this.seatNumberArr[i])
              ] || this.seatNumberArr[i];
          }
        }
      }
    },
    //横向布局-修改座位数量(输入框)
    reviseSeatNum1() {
      // 保留之前的座位号数组
      let tempSeatNumberArr = this.seatNumberArr;
      let tempSeatInputArr = this.seatInput;
      //初始化重复边框提示
      this.inputBorder = [];
      //初始化
      this.isDisabled = [];
      for (let i = 0; i < 81; i++) {
        this.isDisabled.push(false);
      }
      this.seatInput = [];
      this.seatNumberArr = [];
      for (
        let i = 1;
        i <= this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol;
        i++
      ) {
        // this.seatInput.push(i);
        this.seatNumberArr.push(i);
      }
      var j = 0;
      var z = 1;
      for (
        let t = this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol;
        j <
        this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol -
          Number(this.examPlaceRoomForm.seatTotal);
        t -= 1
      ) {
        if (t > 0) {
          this.isDisabled[t - 1] = true;
          // this.seatInput[t - 1] = "";
          this.seatNumberArr[t - 1] = "";
          j++;
        } else {
          t =
            this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol -
            z +
            this.setSeatForm.setSeatCol;
          z++;
        }
      }
      for (
        let i = 0;
        i < this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol;
        i++
      ) {
        if (this.seatNumberArr[i] === "") {
          this.seatInput[i] = "";
        } else {
          this.seatInput[i] =
            tempSeatInputArr[
              tempSeatNumberArr.indexOf(this.seatNumberArr[i])
            ] || this.seatNumberArr[i];
        }
      }
      //更新渲染
      this.$forceUpdate();
    },
    // 横向蛇形-修改座位数量(输入框)
    reviseSeatNum2() {
      this.istransverseSnake = true;
      // 保留之前的座位号数组
      let tempSeatNumberArr = this.seatNumberArr;
      let tempSeatInputArr = this.seatInput;
      //初始化重复边框提示
      this.inputBorder = [];
      //初始化
      this.isDisabled = [];
      for (let i = 0; i < 81; i++) {
        this.isDisabled.push(false);
      }
      this.seatInput = [];
      this.seatNumberArr = [];
      //横向蛇形排布
      for (
        let i = 1;
        i <= this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol;
        i++
      ) {
        // this.seatInput.push(i);
        this.seatNumberArr.push(i);
      }
      let dList = this.arrTrans(this.setSeatForm.setSeatCol, this.seatInput);
      let oList = [];
      let mList = this.arrTrans(
        this.setSeatForm.setSeatCol,
        this.seatNumberArr
      );
      let nList = [];
      dList.forEach((item, index) => {
        if (index % 2 == 0) {
          oList.push(...item);
        } else {
          item = item.reverse();
          oList.push(...item);
        }
      });
      mList.forEach((item, index) => {
        if (index % 2 == 0) {
          nList.push(...item);
        } else {
          item = item.reverse();
          nList.push(...item);
        }
      });

      // this.seatInput = oList;
      this.seatNumberArr = nList;

      var j = 0;
      var z = 1;
      var targetList = [];
      let seatIndexList = [];
      for (
        let i = 0;
        i < this.setSeatForm.setSeatCol * this.setSeatForm.setSeatRow;
        i++
      ) {
        seatIndexList.push(i);
      }
      var seatList = this.arrTrans(this.setSeatForm.setSeatCol, seatIndexList);
      let num =
        this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol -
        Number(this.examPlaceRoomForm.seatTotal);
      if (num > 0) {
        //如果需要禁用的个数小于列数，说明只需要禁用最后一行的某几个
        if (num <= this.setSeatForm.setSeatCol) {
          //如果整体是双数行 说明最后一行应该是倒过来的 首先要禁用index最小的框
          if (this.setSeatForm.setSeatRow % 2 === 0) {
            targetList = seatList[this.setSeatForm.setSeatRow - 1];
            targetList.splice(num, this.setSeatForm.setSeatCol - num);
          } else {
            //由于是单数行 说明最后一行是要从index最大的框开始禁用 为了方便splice处理 先把index数组反转
            targetList = seatList[this.setSeatForm.setSeatRow - 1].reverse();
            targetList.splice(num, this.setSeatForm.setSeatCol - num);
          }
        } else {
          //如果需要禁用的个数大于列数，说明除了禁用最后一行，前面的某几行某几个也被禁用
          //rowNum是获得一个整数 表明有几行完整被禁用 remainder表示出了被完整被禁用的行 其上一行还有几个被禁用
          let rowNum = Math.floor(num / this.setSeatForm.setSeatCol);
          var remainder = num % this.setSeatForm.setSeatCol;
          // deficientCol 定义完整行上一行的行数（用于判断是单数行还是双数行）
          var deficientCol = this.setSeatForm.setSeatRow - rowNum;
          for (let i = this.setSeatForm.setSeatRow - 1; j < rowNum; i -= 1) {
            targetList.push(...seatList[i]);
            j++;
          }
          //完整行执行完后 执行余数 也就是上一行还需要禁用几个
          if (remainder !== 0) {
            if (deficientCol % 2 === 0) {
              //双数行则从后面开始禁用
              let aList = seatList[deficientCol - 1].splice(0, remainder);
              targetList.push(...aList);
            } else {
              let aList = seatList[deficientCol - 1].reverse();
              aList = aList.splice(0, remainder);
              targetList.push(...aList);
            }
          }
        }
      }
      //通过目标数组实现禁用和清空
      for (let i = 0; i < targetList.length; i++) {
        this.isDisabled[targetList[i]] = true;
        // this.seatInput[targetList[i]] = "";
        this.seatNumberArr[targetList[i]] = "";
      }
      for (
        let i = 0;
        i < this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol;
        i++
      ) {
        if (this.seatNumberArr[i] === "") {
          this.seatInput[i] = "";
        } else {
          this.seatInput[i] =
            tempSeatInputArr[
              tempSeatNumberArr.indexOf(this.seatNumberArr[i])
            ] || this.seatNumberArr[i];
        }
      }
      //更新渲染
      this.$forceUpdate();
    },
    //竖向蛇形-修改座位数量(输入框)
    reviseSeatNum3() {
      this.isverticalSnake = true;
      // 保留之前的座位号数组
      let tempSeatNumberArr = this.seatNumberArr;
      let tempSeatInputArr = this.seatInput;
      //初始化重复边框提示
      this.inputBorder = [];
      //初始化
      this.isDisabled = [];
      for (let i = 0; i < 81; i++) {
        this.isDisabled.push(false);
      }
      this.seatInput = [];
      this.seatNumberArr = [];
      //竖向蛇形排布
      let nue = this.testFunc(
        this.setSeatForm.setSeatRow,
        this.setSeatForm.setSeatCol
      );
      let nue2 = this.testFunc(
        this.setSeatForm.setSeatRow,
        this.setSeatForm.setSeatCol
      );
      // this.seatInput = nue;
      this.seatNumberArr = nue2;

      var j = 0;
      var z = 1;
      let num =
        this.setSeatForm.setSeatCol * this.setSeatForm.setSeatRow -
        Number(this.examPlaceRoomForm.seatTotal);
      var targetList = [];
      targetList = this.snakeData(
        this.setSeatForm.setSeatCol,
        this.setSeatForm.setSeatRow,
        num
      );
      //通过目标数组实现禁用和清空
      for (let i = 0; i < targetList.length; i++) {
        this.isDisabled[targetList[i] - 1] = true;
        // this.seatInput[targetList[i] - 1] = "";
        this.seatNumberArr[targetList[i] - 1] = "";
      }
      for (
        let i = 0;
        i < this.setSeatForm.setSeatRow * this.setSeatForm.setSeatCol;
        i++
      ) {
        if (this.seatNumberArr[i] === "") {
          this.seatInput[i] = "";
        } else {
          this.seatInput[i] =
            tempSeatInputArr[
              tempSeatNumberArr.indexOf(this.seatNumberArr[i])
            ] || this.seatNumberArr[i];
        }
      }
      //更新渲染
      this.$forceUpdate();
    },
    //一维数组转换二维数组方法
    arrTrans(num, arr) {
      const iconsArr = []; // 声明数组
      arr.forEach((item, index) => {
        const page = Math.floor(index / num);
        if (!iconsArr[page]) {
          // 判断是否存在
          iconsArr[page] = [];
        }
        iconsArr[page].push(item);
      });
      return iconsArr;
    },
    //通过行列形成竖向蛇形排布的方法
    testFunc(x, y) {
      let arr = [],
        index = 0,
        res = [];
      const lengths = x * y;
      for (let i = 1; i <= lengths; i++) {
        arr.push(i);
      }
      while (index < arr.length) {
        res.push(arr.slice(index, index + x));
        index += x;
      }
      for (let i = 0; i < res.length; i++) {
        i % 2 && res[i].reverse();
      }
      arr = [];
      for (let i = 0; i < x; i++) {
        for (let j = 0; j < y; j++) {
          arr.push(res[j][i]);
        }
      }
      return arr;
    },
    // 竖向蛇形排布辅助方法
    snakeData(x, y, z) {
      let max = x * y,
        arr = [];
      if (x % 2 == 0) {
        var Parity = false;
        var num = JSON.parse(JSON.stringify(x * y)) - (y - 1) * x - x;
      } else {
        var Parity = true;
        var num = JSON.parse(JSON.stringify(x * y)) + x;
      }
      for (let i = 0; i < z; i++) {
        if (i != 0 && !(i % y)) {
          num -= 1;
          Parity = !Parity;
        } else {
          Parity ? (num -= x) : (num += x);
        }
        arr.push(num);
      }
      return arr;
    },
    // 保存按钮，需判断是新增还是编辑
    handleSaveExamPlaceRoom() {
      // 判断一下座位布局是否合理
      if (!this.preSeat()) {
        return;
      }
      if (!this.preSpareSeat()) {
        return;
      }
      if (this.editOrAdd) {
        // 进入此 If 说明是 新增实验室保存
        // 判断表单是否合理
        this.$refs.examPlaceRoomForm.validate((valid) => {
          if (valid) {
            this.copySeatLayoutData();
            // 将数字转变成字符串
            for (let i = 0; i < this.seatLayout.length; i++) {
              for (let j = 0; j < this.seatLayout[i].length; j++) {
                this.seatLayout[i][j] = this.seatLayout[i][j] + "";
              }
            }
            let PlaceRoomParam = {
              name: this.examPlaceRoomForm.roomName,
              code: this.examPlaceRoomForm.roomId,
              seatCount: this.examPlaceRoomForm.seatTotal,
              subjectType: this.value,
              mainMediaUrl: this.examPlaceRoomForm.media,
              sysOrgExamPlaceId: this.examPlaceId,
              sysOrgExamPlaceRoomId: this.examPlaceRoomForm.sysRoomId,
              remark: this.examPlaceRoomForm.remarks,
              seatArrange: this.arrTrans(
                this.setSeatForm.setSeatCol,
                this.seatNumberArr
              ),
              seatCodeArrange: this.seatLayout,
              groupCode: this.examPlaceRoomForm.groupCode,
              groupName: this.examPlaceRoomForm.groupName,
              groupType: this.examPlaceRoomForm.groupType,
            };
            PlaceRoomParam = {
              ...PlaceRoomParam,
              seatArrange: this.trim2DArrSpace(PlaceRoomParam.seatArrange),
              seatCodeArrange: this.trim2DArrSpace(
                PlaceRoomParam.seatCodeArrange
              ),
            };
            addPlaceRoom(PlaceRoomParam).then((res) => {
              let { code, data, msg } = res;
              if (res.success) {
                this.$message.success("添加实验室成功");
                this.isEditExamPlaceDialogVisible = false; // 关闭弹框
                this.$forceUpdate();
                this.fetchData();
                this.expandCurRow(this.examPlaceRoomForm.groupCode);
              } else {
                if (msg === "编码重复，请确认好编码") {
                  msg = "实验室代码已存在，请重新输入!";
                }
                this.$message.error(msg);
                // this.handleCleanFormData();
                this.reviseSeatNum();
              }
            });
          }
        });
      } else {
        // 进入此 if 说明是 编辑实验室保存
        this.$refs.examPlaceRoomForm.validate((valid) => {
          if (valid) {
            this.copySeatLayoutData();
            // 将数字转变成字符串
            for (let i = 0; i < this.seatLayout.length; i++) {
              for (let j = 0; j < this.seatLayout[i].length; j++) {
                this.seatLayout[i][j] = this.seatLayout[i][j] + "";
              }
            }
            let editRoomParam = {
              name: this.examPlaceRoomForm.roomName,
              code: this.examPlaceRoomForm.roomId,
              seatCount: this.examPlaceRoomForm.seatTotal,
              subjectType: this.value,
              mainMediaUrl: this.examPlaceRoomForm.media,
              sysOrgExamPlaceId: this.examPlaceRoomForm.sysPlaceID,
              sysOrgExamPlaceRoomId: this.examPlaceRoomForm.sysRoomId,
              remark: this.examPlaceRoomForm.remarks,
              seatArrange: this.arrTrans(
                this.setSeatForm.setSeatCol,
                this.seatNumberArr
              ),
              seatCodeArrange: this.seatLayout,
            };
            editRoomParam = {
              ...editRoomParam,
              seatArrange: this.trim2DArrSpace(editRoomParam.seatArrange),
              seatCodeArrange: this.trim2DArrSpace(
                editRoomParam.seatCodeArrange
              ),
            };
            editExamRoom(editRoomParam).then((res) => {
              let { code, data, msg } = res;
              if (code == 200) {
                getExamRoom(this.examPlaceId).then((res) => {
                  let { code, data, msg } = res;
                  this.fetchData();
                });
                this.$message.success("编辑实验室成功！");
                this.isEditExamPlaceDialogVisible = false;
                this.expandCurRow(this.examPlaceRoomForm.groupCode);
              } else {
                if (msg === "编码重复，请确认好编码") {
                  msg = "实验室代码已存在，请重新输入!";
                }
                this.$message.error(msg);
              }
            });
          }
        });
      }
    },
    // 取消按钮
    handleCancelExamPlaceRoom() {
      this.isEditExamPlaceDialogVisible = false;
    },
    // 清空表单方法
    handleCleanFormData() {
      this.examPlaceRoomForm = {
        ...this.examPlaceRoomForm,
        roomName: "",
        roomId: "",
        subjectType: "",
        seatTotal: 24,
        examPlaceGroup: [],
        spareMechine: "",
        media: "",
        remarks: "",
      };
      this.seatLayout = [];
      this.setSeatForm = {
        setSeatRow: 6,
        setSeatCol: 4,
      };
      this.isTransverse = false;
      this.isVertical = false;
      this.istransverseSnake = false;
      this.isverticalSnake = false;
    },
    // 设置备用机的数量
    setSpareMechine(val) {
      this.spareMechineNumberArr = [];
      this.spareMechineInput = [];
      let j = 0;
      while (j < 10) {
        this.spareMechineShow.push(false);
        j++;
      }
      for (let i = 1; i <= this.examPlaceRoomForm.spareMechine; i++) {
        this.spareMechineNumberArr.push(this.examPlaceRoomForm.seatTotal + i);
        this.spareMechineInput.push(this.examPlaceRoomForm.seatTotal + i);
        this.spareMechineShow[i - 1] = true;
      }
    },
    // 验证备用机的座位号是否有效
    checkSpareSeat() {
      this.spareMechineInputBorder = [];
      this.spareMechineErrorList = new Set();
      this.inputBorder = [];
      // 座位号是否为空
      for (let i = 0; i < this.spareMechineInput.length; i++) {
        if (this.spareMechineInput[i] == "") {
          this.spareMechineInputBorder[i] = true;
          this.spareMechineErrorList.add(1);
        }
      }
      // 备用机的座位号是否与其他座位冲突
      for (let i = 0; i < this.seatInput.length; i++) {
        if (this.seatInput[i] != "" && !isNaN(+this.seatInput[i])) {
          this.seatInput[i] = +this.seatInput[i];
        }
        for (let j = 0; j < this.spareMechineInput.length; j++) {
          if (!isNaN(+this.spareMechineInput[j])) {
            this.spareMechineInput[j] = +this.spareMechineInput[j];
          }
          if (this.seatInput[i] == this.spareMechineInput[j]) {
            this.spareMechineInputBorder[j] = true;
            this.spareMechineErrorList.add(2);
            this.inputBorder.push(i + 1);
          }
        }
      }
      // 备用机座位号大于上限
      // for (let i = 0; i < this.spareMechineInput.length; i++) {
      //   if (
      //     this.spareMechineInput[i] != "" &&
      //     !isNaN(+this.spareMechineInput[i])
      //   ) {
      //     this.spareMechineInput[i] = +this.spareMechineInput[i];
      //   }
      //   if (
      //     this.spareMechineInput[i] >
      //     this.examPlaceRoomForm.seatTotal + this.examPlaceRoomForm.spareMechine
      //   ) {
      //     this.spareMechineInputBorder[i] = true;
      //     this.spareMechineErrorList.add(3);
      //   }
      // }
      // 备用机座位号冲突
      if (this.spareMechineInput <= 1) {
        return;
      }
      for (let i = 0; i < this.spareMechineInput.length; i++) {
        if (!isNaN(+this.spareMechineInput[i])) {
          this.spareMechineInput[i] = +this.spareMechineInput[i];
        }
        let index1 = this.spareMechineInput.indexOf(this.spareMechineInput[i]);
        let index2 = this.spareMechineInput.lastIndexOf(
          this.spareMechineInput[i]
        );
        if (index1 !== index2) {
          this.spareMechineInputBorder[index1] = true;
          this.spareMechineInputBorder[index2] = true;
          this.spareMechineErrorList.add(2);
        }
      }
    },
    // 将验证结果转变成 布尔值
    preSpareSeat() {
      //判断一下是否有整行整列为空
      if (this.spareMechineErrorList.has(1)) {
        this.$message.error("备用机座位号不能为空");
        return false;
      }
      //判断一下是否有整行整列为空
      if (this.spareMechineErrorList.has(2)) {
        this.$message.error("座位号不能重复");
        return false;
      }
      // if (this.spareMechineErrorList.has(3)) {
      //   this.$message.error("座位号只能是1-座位数量范围内的数字");
      //   return false;
      // }
      return true;
    },
    // 调用该方法，可以调整编辑实验室弹框的座位布局情况
    handleEditExamPlaceSeatLayoutStatus(arr) {
      if (arr.length <= 1 || arr[0].length <= 1) {
        return;
      }
      if (arr[0][1] === 2) {
        // 说明是横向排列，再进一步判断是否是蛇形
        if (arr[1][0] === arr[0].length + 1) {
          // 横排顺序
          this.transverse();
        } else {
          // 横排蛇形
          this.transverseSnake();
        }
      } else {
        if (arr[0][1] === arr.length + 1) {
          // 竖排顺序
          this.vertical();
        } else {
          // 竖向蛇形
          this.verticalSnake();
        }
      }
    },
    // 测试
    handleRowClick(val) {
      this.expandCurRow(val.groupCode);
    },
    // 展开或者关闭
    expandCurRow(key) {
      if (this.expandRowKeys.indexOf(key) >= 0) {
        // 说明该行已经展开，现在就折叠
        this.expandRowKeys.splice(this.expandRowKeys.indexOf(key), 1);
      } else {
        this.expandRowKeys.push(key);
      }
    },
    // 调用该方法将未编辑的实验室去除
    removeUneditExamPlaceRoom(groupCode) {
      this.examPlaceGroupData[groupCode] = this.examPlaceGroupData[
        groupCode
      ].filter((item) => item.name !== "<未编辑>");
    },
    // 调用该方法加入未编辑的实验室
    addUneditExamPlaceRoom(row) {
      const groupCode = row.groupCode;
      const groupName = row.groupName;
      const groupType = row.groupType;
      if (this.examPlaceGroupData[groupCode].length < 3) {
        let diff = 3 - this.examPlaceGroupData[groupCode].length;
        let tempNum = [1, 2, 3];
        let initSeatArrange = [];
        for (let i = 1; i <= 6; i++) {
          let arr = [];
          for (let j = 1; j <= 4; j++) {
            arr.push(j + (i - 1) * 4);
          }
          initSeatArrange.push(arr);
        }
        this.examPlaceGroupData[groupCode].forEach((item) => {
          tempNum.splice(tempNum.indexOf(item.subjectType), 1);
        });
        for (let i = 0; i < diff; i++) {
          this.examPlaceGroupData[groupCode].push({
            name: "<未编辑>",
            code: "-",
            subjectType: tempNum.pop(),
            seatCount: 24,
            examPlaceGroup: "",
            spareMechine: "",
            mainMediaUrl: "",
            remark: "",
            sysOrgExamPlaceRoomId: "",
            sysOrgExamPlaceld: "",
            groupCode,
            groupName,
            groupType,
            sysOrgExamPlaceName: "",
            assistMediaUrl: "",
            address: "",
            nameshort: "",
            seatArrange: initSeatArrange,
            seatCodeArrange: initSeatArrange,
          });
        }
      }
    },
    // 调用该方法将二维数组中的空串去除
    trim2DArrSpace(arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].length; j++) {
          if (arr[i][j] === "") {
            arr[i].splice(j, 1);
            j = j - 1;
          }
        }
      }
      return arr;
    },
    // 调用该方法将二维数组中的空串还原
    add2DArrSpace(arr) {
      for (let i = 0; i < this.setSeatForm.setSeatRow; i++) {
        if (
          i === this.setSeatForm.setSeatRow - 1 &&
          this.setSeatForm.setSeatRow % 2 === 0 &&
          this.istransverseSnake
        ) {
          while (arr[i].length < this.setSeatForm.setSeatCol) {
            arr[i].unshift("");
          }
          continue;
        }
        for (let j = 0; j < this.setSeatForm.setSeatCol; j++) {
          if (!arr[i][j]) {
            arr[i][j] = "";
          }
        }
      }
      return arr;
    },
  },
  filters: {
    groupTypeFilters(key) {
      switch (key) {
        case 1:
          return "顺序实验室";
        case 2:
          return "叠加实验室";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .groupInfoTable {
  .el-table__cell {
    &.el-table__expanded-cell {
      padding: 0;
    }
  }
}
.addExamPlaceTitle {
  display: block;
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  font-size: 22px;
  font-weight: 700;
}
.editExamPlaceForm {
  display: flex;
}
.leftFormContainer {
  width: 500px;
  border-right: 1px solid #eee;
  padding-right: 20px;
}
.leftFormTitle {
  margin: 20px 0;
  font-size: 20px;
}
::v-deep .formItem {
  .el-form-item__label {
    font-size: 18px;
  }
}
::v-deep .examPlaceGroup {
  .el-form-item__label::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
.rightFormContainer {
  padding: 20px;
  width: 1200px;
  height: 500px;
  overflow: auto;
}
.rightFormTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.box {
  display: inline-block;
  width: 90px;
  height: 40px;
  border: 2px solid #1890ff;
  font-size: 16px;
  color: #1890ff;
  text-align: center;
  line-height: 40px;
  margin-left: 30px;
  cursor: pointer;
}
.box-select {
  background-color: #1890ff;
  color: #ffffff;
}
.borderRed {
  border: 1px solid #f56c6c;
  border-radius: 4px;
}
.seatNumber,
.seatNumber2 {
  display: inline-block;
  width: 30px;
  text-align: center;
  position: absolute;
  left: -30px;
  height: 38px;
  line-height: 38px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  background-color: rgb(242, 242, 242);
}
.spareMechineTitle {
  margin-top: 20px;
  margin-left: 20px;
  position: relative;
  &::before {
    content: "";
    content: "";
    display: block;
    position: absolute;
    width: 578px;
    height: 1px;
    left: 52px;
    margin-top: 10px;
    top: 0;
    z-index: 5;
    background: #eee;
  }
}
::v-deep .spareMechineInput {
  input {
    border-color: rgb(250, 205, 145);
  }
}
</style>
